import { useEffect, useState } from "react";
import AdminContainer from "../component/AdminContainer";
import "../styles/Dashboard.css";
import CardDashbord from "../component/cardDashbord";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "@reduxjs/toolkit";

import {
  DashBoardInfoState,
  getDashboardInfo,
} from "../redux/slices/dashboardSlice";
import CircularLoader from "../component/loader/CircularLoader";

function DashboardPage() {
  // const dashboardInfo = useDashbordInfo();
  const dispatch = useDispatch();

  const dashboardInfo = useSelector<
    Record<string, DashBoardInfoState>,
    DashBoardInfoState
  >((state) => state.dashboard).data;

  console.log("dashboardInfo", dashboardInfo);

  useEffect(() => {
    !dashboardInfo && dispatch(getDashboardInfo() as unknown as AnyAction);
  }, [dashboardInfo, dispatch]);

  const totalPayments =
    dashboardInfo?.payments?.reduce(
      (total: number, payment: any) => total + parseFloat(payment.amount),
      0
    ) ?? 0;

  return (
    <AdminContainer>
      <div className="HomePage__about__content bounce-animation w-100 d-flex flex-column rounded p-4 h-100">
        <h1 className="text-center text-primary">
          Tableau de bord de votre region
        </h1>

        <div className=" d-flex flex gap-4 justify-content-center align-items-center w-100 flex-wrap">
          <CardDashbord
            icon={<i className="fa fa-chart-line"></i>}
            title="Nombre de participants"
            description={dashboardInfo?.participantsCount}
          />
          <CardDashbord
            icon={<i className="fa fa-chart-line"></i>}
            title="Nombre de membre"
            description={dashboardInfo?.membersCount}
          />
          <CardDashbord
            icon={<i className="fa fa-chart-line"></i>}
            title="Cartes vendues"
            description={dashboardInfo?.cardsCount}
          />
          <CardDashbord
            icon={<i className="fa fa-chart-line"></i>}
            title="Revenus des cartes vendues"
            description={`FCFA ${totalPayments}`}
          />
        </div>
      </div>
    </AdminContainer>
  );
}

export default DashboardPage;
