import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import userReducer from "./slices/userSlice";
import memberReducer from "./slices/memberSlice";
import dashboardReducer from "./slices/dashboardSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    member: memberReducer,
    dashboard: dashboardReducer,
  },
  devTools: process.env.NODE_ENV === "development",
});

setupListeners(store.dispatch);
