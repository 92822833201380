import axios from "axios";
import { Member } from "../models/Member";
axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "authToken"
)}`;

const getCurrentUser = () => {
  return axios
    .get(`user`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    })
    .then((data: any) => {
      return data;
    });
};

const getDashboardInfo = () => {
  return axios
    .get("admin/dashboard-infos", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    })
    .then((response: { data: any }) => {
      console.log("data from getDashboardInfo", response.data);
      return response.data;
    });
};

// const getDashboardInfo = () => {
//   return axios
//     .get(`${process.env.REACT_APP_API_BASE_URL}token`)
//     .then((response: { data: any }) => {
//       return axios({
//         url: `${process.env.REACT_APP_API_BASE_URL}admin/dashboard-infos`,
//         method: "get",
//         headers: {
//           "X-CSRF-TOKEN": response.data,
//         },
//       });
//     })
//     .catch((error) => {
//       console.error("Error fetching dashboard info:", error);
//       throw error;
//     });
// };

export const updateUser = (userToPatch: any) => {
  return axios
    .post(`user`, userToPatch, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    })
    .then((data: any) => {
      return data;
    });
};

const signup = async (data: any) => {
  return axios
    .get(`${process.env.REACT_APP_API_BASE_URL}token`)
    .then((response: { data: any }) => {
      return axios({
        url: `${process.env.REACT_APP_API_BASE_URL}signup`,
        method: "post",
        data: {
          ...data,
        },
        headers: {
          "X-CSRF-TOKEN": response.data,
        },
      });
    });
};

const addToTeam = async (data: any) => {
  return axios
    .get(`${process.env.REACT_APP_API_BASE_URL}token`)
    .then((response: { data: any }) => {
      return axios({
        url: `${process.env.REACT_APP_API_BASE_URL}add-to-team`,
        method: "post",
        data: {
          ...data,
        },
        headers: {
          "X-CSRF-TOKEN": response.data,
        },
      });
    });
};

const signin = async (data: any) => {
  return axios
    .get(`${process.env.REACT_APP_API_BASE_URL}token`)
    .then((response: { data: any }) => {
      return axios({
        url: `${process.env.REACT_APP_API_BASE_URL}signin`,
        method: "get",
        params: {
          ...data,
        },
        headers: {
          "X-CSRF-TOKEN": response.data,
        },
      });
    });
};

const verifyOTP = (otp: any) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}verify-otp/${otp}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  });
};

const resendOTP = () => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}resend-otp`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  });
};
const askNewPasswordLink = (data: any) => {
  return axios
    .get(`${process.env.REACT_APP_API_BASE_URL}token`)
    .then((response: { data: any }) => {
      // console.log(response.data);
      return axios({
        url: `${process.env.REACT_APP_API_BASE_URL}reset-password`,
        method: "get",
        params: {
          ...data,
        },
        headers: {
          "X-CSRF-TOKEN": response.data,
        },
      });
    });
};

const changePassword = ({
  token,
  password,
}: {
  token: string;
  password: string;
}) => {
  return axios
    .get(`${process.env.REACT_APP_API_BASE_URL}token`)
    .then((response: { data: any }) => {
      return axios({
        url: `${process.env.REACT_APP_API_BASE_URL}reset-password/${token}/${password}`,
        method: "get",
        headers: {
          "X-CSRF-TOKEN": response.data,
        },
      });
    });
};

const addParticipation = (data: { message: string; game: string }) => {
  return axios
    .get(`${process.env.REACT_APP_API_BASE_URL}token`)
    .then((response: { data: any }) => {
      return axios({
        url: `${process.env.REACT_APP_API_BASE_URL}participations`,
        method: "post",
        data: {
          ...data,
        },
        headers: {
          "X-CSRF-TOKEN": response.data,
        },
      });
    });
};

const getAll = () => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}members`,
    method: "get",
  });
};

const getMemberRegion = () => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}members-by-region`,
    method: "get",
  });
};

// const getAll = () => {
//   return axios
//     .get(`${process.env.REACT_APP_API_BASE_URL}members`)
//     .then((data) => {
//       console.log("data team", data);
//       return data;
//     });
// };

const generateCard = (id: any) => {
  return axios
    .get(`/regenerate-card/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    })
    .then((data) => {
      return data;
    });
};

export const UserService = {
  getCurrentUser,
  updateUser,
  signup,
  signin,
  verifyOTP,
  resendOTP,
  askNewPasswordLink,
  changePassword,
  addParticipation,
  getAll,
  generateCard,
  addToTeam,
  getDashboardInfo,
  getMemberRegion,
};
