import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useRef, useState, useEffect, LegacyRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { handlePayment, updatePayment } from "../../redux/slices/paymentSlice";
import PaymentService from "../../services/PaymentService";
import CircularLoader from "../loader/CircularLoader";
import "../../styles/component/PaymentForm.css";
import { AnyAction } from "@reduxjs/toolkit";
import { PaymentMethod } from "../payment/PaymentMethod";
import PriceDetail from "../payment/PriceDetail";
import { formatPrice } from "../../utils/utils";
import { Id } from "react-toastify/dist/types";
import { useTranslation } from "react-i18next";
import { PAYMENT_STATUS } from "../../utils/constant";

interface PaymentFormOptions {
    onGoBack: any;
    amount: any;
    title: any;
    stripe_fee: any;
    dataForPayment: any;
    onPaymentSuccess: any;
}

const PaymentForm = ({
    onGoBack,
    amount,
    title,
    stripe_fee,
    dataForPayment,
    onPaymentSuccess,
}: PaymentFormOptions) => {
    const stripe = useStripe();
    const elements = useElements();
    const mobileFormRef = useRef<HTMLFormElement>();
    const cardFormRef = useRef<HTMLFormElement>();
    const operatorRef = useRef<HTMLInputElement>();
    const phoneRef = useRef<HTMLInputElement>();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { t } = useTranslation();

    const [onPayment, setOnPayment] = useState(false);
    const [onWaitingMobilePayment, setOnWaitingMobilePayment] = useState(false);
    const [pendingPaymentId, setPendingPaymentId] = useState(null);
    const [pendingToastId, setPendingToastId] = useState<Id>(0);
    const [onStripePayment, setOnStripePayment] = useState(false);

    var paymentStatusCheckerId: string | number | NodeJS.Timeout | undefined;
    var paymentStatusCheckerElapsed = 0;

    const [total, setTotal] = useState({ mobile: 0, cc: 0 });

    // const handlePaymentSuccess = (data) => {
    //   dispatch(updatePayment(data)).then(() => {
    //     localStorage.setItem("cardIdToPreview", data.data.subscription.id);
    //     setTimeout(() => {
    //       navigate("/payment");
    //     }, 3000);
    //     return;
    //   });
    // };

    const handlePaymentSuccess = (data: any) => {
        // rediriger l'utilisateur et afficher sa carte
        dispatch(updatePayment(data) as unknown as AnyAction).then(() => {
            localStorage.setItem("cardIdToPreview", data.data.subscription.id);
            toast.dismiss(pendingToastId);
            onPaymentSuccess();
        });
    };

    const handleOpenForm = (e: { target: any }) => {
        if (
            cardFormRef &&
            mobileFormRef &&
            cardFormRef.current &&
            mobileFormRef.current
        )
            if (e.target.value === "mobile") {
                mobileFormRef.current.classList.add("open");
                cardFormRef.current.classList.remove("open");
            } else {
                cardFormRef.current.classList.add("open");
                mobileFormRef.current.classList.remove("open");
            }
    };

    const delay = (ms: number) =>
        new Promise((resolve) => setTimeout(resolve, ms));

  const startCheckingPaymentStatus = async (id_payment: string, reference: string) => {
    let paymentStatusCheckerEllapsed = 0;
    const maxWaitTime = 1000 * 120;
    const checkInterval = 4000;
    const INITIALIZE = "Progress";
    let PROGRESS = "Progress";

    let counter = 0;

    while (INITIALIZE === PROGRESS) {
      try {
        const { data } = await PaymentService.get(id_payment, reference);
        PROGRESS = data ?? data.status;

        console.log("data", data);

                console.log("Status:", PROGRESS);

                if (PROGRESS === PAYMENT_STATUS.RECEIVED) {
                    // Paiement réussi
                    setOnPayment(false);
                    setOnWaitingMobilePayment(false);
                    handlePaymentSuccess(data);
                    break;
                } else if (PROGRESS === PAYMENT_STATUS.REJECTED) {
                    // Paiement rejeté
                    setOnPayment(false);
                    setOnWaitingMobilePayment(false);

          toast.warn(
            "Le paiement a été rejeté, veuillez réessayer après quelques minutes.",
            { autoClose: false }
          );
          break;
        }
        if (INITIALIZE === PROGRESS && counter > 10) {
          break;
        }
        counter++;
      } catch (err) {
        console.error(
          "Erreur lors de la vérification du statut du paiement :",
          err
        );
        toast.error(
          "Une erreur s'est produite lors de la vérification du paiement."
        );
      }

      await delay(checkInterval);
      paymentStatusCheckerEllapsed += checkInterval;
    }

        if (paymentStatusCheckerEllapsed >= maxWaitTime) {
            setOnPayment(false);
            setOnWaitingMobilePayment(false);
            toast.warn("La transaction a été annulée après un délai d'attente.");
        }
    };

    const handleMobilePayment = (e: any) => {
        e.preventDefault();
        if (operatorRef && operatorRef.current) {
            const data = {
                payment_method: operatorRef.current.value,
                ...dataForPayment,
                phone: phoneRef?.current?.value,
            };

            setOnPayment(true);
            dispatch(handlePayment(data) as unknown as AnyAction)
                .unwrap()
                .then((data: any) => {
                    console.log('before use', data);
                    if (data.errors) {
                        toast.error(data.errors.join("<br/>"));
                        setOnPayment(false);
                    } else {
                        setOnPayment(false);
                        setPendingPaymentId(data.data.payment.id);
                        setOnWaitingMobilePayment(true);
                        setPendingToastId(
                            toast.info(
                                t("Please validate the payment on your mobile please"),
                                { autoClose: false }
                            )
                        );
                        startCheckingPaymentStatus(data.data.payment.id, data.data.reference);
                    }
                })
                .catch((err: any) => {
                    toast.error(
                        t(
                            "An error occurred during payment, please check your information, your balance and try again."
                        )
                    );
                    setOnPayment(false);
                });
        }
    };

    const handleStripePayment = async (e: any, stripe: any, elements: any) => {
        e.preventDefault();
        setOnStripePayment(true);
        const cardElement = elements.getElement(CardElement);
        const { error, token } = await stripe.createToken(cardElement);
        if (error) {
            toast.error("Error: " + error.message);
        } else {
            // ... SEND to your API server to process payment intent
            const data = {
                payment_method: "cc",
                stripe_data: token,
                ...dataForPayment,
                phone: phoneRef?.current?.value,
            };

            dispatch(handlePayment(data) as unknown as AnyAction)
                .unwrap()
                .then((data: any) => {
                    if (data.errors) {
                        toast.error(data.errors.join("<br/>"));
                        setOnStripePayment(false);
                    } else {
                        handlePaymentSuccess(data);
                    }
                })
                .catch((err: any) => {
                    toast.error(
                        t(
                            "An error occurred during payment, please check your information, your balance and try again."
                        )
                    );
                    setOnStripePayment(false);
                    console.log(err);
                });
        }
    };

    const [paymentMethod, setPaymentMethod] = useState({
        value: "om",
        name: "Orange Money",
    });

    const handleSelectPaymentMethod = (method: any) => {
        setPaymentMethod(method);
        if (operatorRef && operatorRef.current) {
            if (["om", "momo"].indexOf(method.value) >= 0) {
                operatorRef.current.value = method.value;
                handleOpenForm({ target: { value: "mobile" } });
            } else {
                handleOpenForm({ target: { value: "cc" } });
            }
        }
    };

    useEffect(() => {
        setTotal((value) => {
            return {
                ...value,
                mobile: (2.7 * parseFloat(amount)) / 100 + parseFloat(amount),
            };
        });
        setTotal((value) => {
            return {
                ...value,
                cc: (stripe_fee * parseFloat(amount)) / 100 + parseFloat(amount),
            };
        });
    }, [amount, dataForPayment, stripe_fee]);

    return (
        <div
            style={{ minHeight: "40vh" }}
            className="ProfileForm bounce-animation HomePage__about__content flex-start w-100 d-flex flex-column p-3"
        >
            {!onPayment && !onWaitingMobilePayment ? (
                <div className="ProfileF orm  flex-start w-100 d-flex flex-column ">
                    <div className="col-12 col-sm-10 col-md-8 col-xl-7 mx-auto px-4">
                        <h4 className="text-dark fw-bold text-uppercase">
                            {" "}
                            <i
                                title="retour"
                                onClick={onGoBack}
                                className="fa fa-arrow-left-long cursor-pointer"
                            />{" "}
                            {t("Payment Method")}
                        </h4>
                        <PaymentMethod onSelect={handleSelectPaymentMethod} />
                        <form
                            onSubmit={handleMobilePayment}
                            ref={mobileFormRef as LegacyRef<HTMLFormElement>}
                            className="mobileForm"
                        >
                            <div className="form-check p-0 m-0 py-1">
                                <input
                                    value={"mobile"}
                                    onClick={handleOpenForm}
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault2"
                                />
                                <label className="form-check-label text-left">
                                    {t("PayBy")} {paymentMethod.name} ({formatPrice(total.mobile)}
                                    )
                                </label>
                            </div>
                            <PriceDetail
                                key={"PriceDetail-0"}
                                price={parseInt(amount)}
                                fee={2.7}
                            />
                            <div className="py-1 visually-hidden">
                                <select
                                    ref={operatorRef as unknown as LegacyRef<HTMLSelectElement>}
                                    name="operator"
                                    className="form-select"
                                    id="floatingSelect"
                                    aria-label="Floating label select example"
                                >
                                    <option value="om">Orange</option>
                                    <option value="momo">MTN</option>
                                </select>
                                <label htmlFor="floatingSelect">
                                    {t("Select mobile operator")}
                                </label>
                            </div>
                            <div className="mb-3 pt-2">
                                <label htmlFor="floatingInput">{t("Phone number")}</label>
                                <input
                                    className="w-100"
                                    required
                                    ref={phoneRef as unknown as LegacyRef<HTMLInputElement>}
                                    name="phone"
                                    type="tel"
                                    id="floatingInput"
                                    placeholder={"" + t("Phone number")}
                                />
                            </div>
                            <button
                                type="submit"
                                className="esport esport-btn-normal w-100 my-2"
                            >
                                {t("Pay")}
                            </button>
                        </form>

                        <form
                            onSubmit={(event) => handleStripePayment(event, stripe, elements)}
                            ref={cardFormRef as LegacyRef<HTMLFormElement>}
                            className="mobileForm"
                        >
                            <div className="form-check p-0 m-0 py-1">
                                <input
                                    value={"cc"}
                                    onClick={handleOpenForm}
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault1"
                                />
                                <label
                                    className="form-check-label text-left"
                                    htmlFor="flexRadioDefault1"
                                >
                                    {t("PayBy")} {paymentMethod.name} ({formatPrice(total.cc)})
                                </label>
                            </div>
                            {total.mobile > 0 && (
                                <PriceDetail
                                    key={"PriceDetail-1"}
                                    price={parseInt(amount)}
                                    fee={stripe_fee}
                                />
                            )}
                            <div className="CardElement my-4 py-4 form-control">
                                <CardElement />
                            </div>
                            <button
                                disabled={!stripe || onStripePayment}
                                type="submit"
                                className="esport esport-btn-normal w-100  my-2"
                            >
                                {!onStripePayment ? t("Pay") : <CircularLoader />}
                            </button>
                        </form>
                    </div>
                </div>
            ) : (
                <CircularLoader
                    content={
                        !onWaitingMobilePayment ? (
                            <small className="text-muted">{t("OnPayment")}</small>
                        ) : (
                            t("ConfirmPayment")
                        )
                    }
                />
            )}
        </div>
    );
};

const StripePaymentForm = (
    props: JSX.IntrinsicAttributes & PaymentFormOptions
) => <PaymentForm {...props} />;

export default StripePaymentForm;
